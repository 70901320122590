import React from 'react';
import './App.scss';
import Layout from './components/Layout/Layout'


function App() {

  return (
      <div className="App">
        <Layout />
      </div>
  );
}

export default App;
